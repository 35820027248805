<template>
  <div>
    <div class="custom-search d-flex justify-content-start mb-2">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        v-b-modal.modal-create
        size="lg"
        @click="$router.push({ name: 'integrations-add' })"
        :disabled="getIsDisabled"
      >
        Добавить интеграцию
      </b-button>
    </div>
    <overview-table
      :integrations="getIntegrations.data"
      :isDisabled="getIsDisabled"
      @setStatus="setStatus"
      @deleteIntegration="deleteIntegration"
      @editIntegration="editIntegration"
    >
    </overview-table>
  </div>
</template>

<script>
import OverviewTable from "./OverviewTable.vue";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BForm,
  VBPopover,
  BPopover,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    OverviewTable,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BForm,
    BPopover,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  computed: {
    ...mapGetters("integrations", ["getIntegrations"]),
    ...mapGetters("user", ["getIsDisabled"]),
  },
  metaInfo() {
    return {
      title: "moovee - Интеграции",
    };
  },
  methods: {
    ...mapActions("integrations", [
      "requestIntegrations",
      "setIntegrationStatus",
      "removeIntegration",
    ]),
    ...mapActions("user", ["requestUserData"]),
    setStatus(integration) {
      const key = Object.keys(this.getIntegrations.answer).find(
        (key) =>
          this.getIntegrations.answer[key].displayId === integration.displayId
      );
      this.setIntegrationStatus({
        id: key,
        status: integration.status,
        displayId: integration.displayId,
      });
    },
    deleteIntegration(integration) {
      const key = Object.keys(this.getIntegrations.answer).find(
        (key) =>
          this.getIntegrations.answer[key].displayId === integration.displayId
      );
      this.$swal({
        title: "Вы действительно хотите удалить интеграцию?",
        text: "После удаления, её невозможно будет восстановить",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да, удалить!",
        cancelButtonText: "Отменить",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeIntegration(key);
        }
      });
    },
    editIntegration(integration) {
      if (this.getIsDisabled) {
        this.$router.push({ name: "pages-pricing" });
      } else {
        const key = Object.keys(this.getIntegrations.answer).find(
          (key) =>
            this.getIntegrations.answer[key].displayId === integration.displayId
        );
        switch (integration.type) {
          case "Webhook": {
            this.$router.push({
              name: "integrations-webhook",
              params: { id: key },
            });
            break;
          }
          case "Email": {
            this.$router.push({
              name: "integrations-email",
              params: { id: key },
            });
            break;
          }
          case "googleAnalytics": {
            this.$router.push({
              name: "integrations-google",
              params: { id: key },
            });
            break;
          }
          case "yandexMetrika": {
            this.$router.push({
              name: "integrations-yandex",
              params: { id: key },
            });
            break;
          }
          case "facebookPixel": {
            this.$router.push({
              name: "integrations-facebook",
              params: { id: key },
            });
            break;
          }
        }
      }
    },
  },
  created() {
    this.requestIntegrations();
    this.requestUserData();
  },
};
</script>
